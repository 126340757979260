<template>
  <el-row type="flex" justify="center">
    <el-col :xs="24" :sm="16" :md="12">
      <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <input type="hidden" v-model="ruleForm.id" />
        <el-form-item label="操作人ID" prop="id">
          <el-input v-model="ruleForm.id" :readonly="true"></el-input>
        </el-form-item>
        <el-form-item label="姓名" prop="name">
          <el-input v-model="ruleForm.name" :readonly="true"></el-input>
        </el-form-item>
        <el-form-item label="IP地址" prop="ip">
          <el-input v-model="ruleForm.ip" :readonly="true"></el-input>
        </el-form-item>
        <el-form-item label="路径" prop="permission">
          <el-input v-model="ruleForm.permission" :readonly="true"></el-input>
        </el-form-item>
        <el-form-item label="请求头" prop="header">
          <el-input v-model="ruleForm.header" :readonly="true"></el-input>
        </el-form-item>
        <el-form-item label="请求内容" prop="body">
          <el-input
          :readonly="true"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4}"
            placeholder="暂无内容"
            v-model="ruleForm.body"
          ></el-input>
        </el-form-item>
        <el-form-item label="操作时间" prop="timestamp">
          <el-input v-model="ruleForm.timestamp" :readonly="true"></el-input>
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>
</template>

<script>

export default {
  props: ["log"],
  mounted: function () {
    this.loadCgy();
  },

  data() {
    return {
      ruleForm: {
        id: "",
        name: "",
        ip: "",
        permission: "",
        header: "",
        body: "",
        timestamp: "",
      },
    };
  },
  watch:{
    log(val){
      this.ruleForm = val
    }
  },
  methods: {
    loadCgy: function () {
      console.log(this.log);
      this.ruleForm = this.log;
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>