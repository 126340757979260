<template>
  <el-dialog title="新增模板" :visible.sync="dialogFormVisible" width="640px">
    <el-form :model="form">
      <!-- <el-form-item
        label="模板名称"
        :label-width="formLabelWidth"
        :required="true"
      >
        <el-input
          v-model="form.name"
          autocomplete="off"
          placeholder="请输入模板名称"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="模板文件"
        :label-width="formLabelWidth"
        :required="true"
      >
        <el-upload
          class="upload-demo"
          :action="uploadUrl"
          :accept="accept"
          drag
          :limit="1"
          :before-upload="beforeUpload"
          :on-success="onSuccess"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">
            只能上传jpg/png/doc/docx/pdf文件，且不超过10M
          </div>
        </el-upload>
      </el-form-item> -->
      <el-form-item
        label="选择客户"
        :label-width="formLabelWidth"
        :required="true"
      >
      <el-select v-model="form.tenantIds" filterable clearable multiple style="width: 100%;">
        <el-option v-for="elem of tenantList" :key="elem.id" :value="elem.id" :label="elem.name"></el-option>
      </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      dialogFormVisible: false,
      form: {
        tenantIds:[]
      },
      accept:
        'image/jpeg,image/png,application/msword,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/wps-office.docx',
      formLabelWidth: '120px',
      uploadUrl: process.env.VUE_APP_BASE_URL + '/template/upload-doc',
      tenantList:[]
    }
  },
  created(){
    this.getTenant()
  },
  methods: {
    getTenant(){
      this.api.salaryApi.finTenants().then(res=>{
          this.tenantList = res.data
      })
    },
    open() {
      this.form.tenantIds = []
      this.dialogFormVisible = true
    },
    beforeUpload(file) {
      console.log(file)
      const size = file.size / 1024 / 1024
      const typeCheck = [
        'image/jpeg',
        'image/png',
        'application/msword',
        'application/pdf',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/wps-office.docx',
      ]
      if (!typeCheck.includes(file.type)) {
        this.$message.error('仅支持jpg/png/pdf/word格式的文件')
        return
      }
      if (size > 10) {
        this.$message.error('最大支持10M的文件')
        return
      }
      return true
    },
    onSuccess(response) {
      this.form.fileId = response.data.fileId
      this.form.fileUrl = response.data.fileUrl
    },
    submit() {
      if (!this.form.tenantIds.length) {
        this.$message.error('请选择客户')
        return
      }
      const data = {
        redirectUrl:
          window.location.origin +
          window.location.pathname +
          'contract-success.html',
        ...this.form,
      }
      this.api.contractApi.createTemplate(this.form).then((res) => {
        if (res.data.code === 200) {
          this.$message.success('创建模板成功')
          this.dialogFormVisible = false
          this.$emit('success', res.data.data)
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
  },
}
</script>
<style scoped>
.upload-demo {
  text-align: left;
}
</style>

