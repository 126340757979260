<template>
  <el-row type="flex" justify="center">
    <el-col :xs="24" :sm="16" :md="12">
      <el-form
        :model="ruleForm"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <input type="hidden" v-model="ruleForm.id" />
        <el-form-item label="机构编号:">
          <el-input v-model="list.code"></el-input>
        </el-form-item>
        <el-form-item label="机构名称:">
          <el-input :value="list.name"></el-input>
        </el-form-item>

        <el-form-item label="机构简称:">
          <el-input v-model="list.abbreviation"></el-input>
        </el-form-item>

        <el-form-item label="机构地址:">
          <el-input v-model="list.address"></el-input>
        </el-form-item>

        <el-form-item label="所属公司:">
          <el-input v-model="list.company"></el-input>
        </el-form-item>
        <el-form-item label="是否启用:">
          <el-radio v-model="flagtype" label="1">是</el-radio>
          <el-radio v-model="flagtype" label="0">否</el-radio>
        </el-form-item>
        <el-form-item label="机构描述:">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入描述"
            v-model="list.content"
          >
          </el-input>
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>
</template>

<script>
import { findOrganizationById } from "@/service/moudules/platformApi";
import { organizationUpdate } from "@/service/moudules/platformApi";
export default {
  props: ["editOrganization"],
  mounted: function () {
    this.ruleForm.id = this.editOrganization.id;
    this.findOrganizationByIdApi();
  },

  data() {
    return {
      textarea: "",

      flagtype: "1",

      ruleForm: {
        id: "",
      },
      list:{
        id:"",
        name:"",
        abbreviation:"",
        address:"",
        state:"",
        content:"",
        company:"",
        legal:"",
        code:""
      }
    };
  },

  methods: {
    async findOrganizationByIdApi() {
      let response = await this.api.platformApi.findOrganizationById(
        this.ruleForm
      );
      this.list=response.data.data
      this.flagtype=String(response.data.data.state)
      console.log("=-=-=-=-=",response.data.data)
    
    },
    async organizationUpdateApi() {
      let response = await this.api.platformApi.organizationUpdate(
        this.list
      );
      if(response.data.data==true){
         this.$message({
                message: "修改成功",
                type: "success",
              });
      }else{
         this.$message({
                message: "修改失败",
                type: "warning",
              });
      }
    },
    submitForm() {
      this.list.state = parseInt(this.flagtype);
     this.organizationUpdateApi()
      
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style scope>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.buttomWidth {
  width: 100%;
}
</style>