<template>
  <el-row type="flex" justify="center">
    <el-col :xs="24" :sm="10" :md="12">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="名称" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="数据值" prop="datavalue">
          <el-input v-model="ruleForm.datavalue"></el-input>
        </el-form-item>
        <el-form-item label="描述" prop="details">
          <el-input v-model="ruleForm.details"></el-input>
        </el-form-item>
        <el-form-item label="排序值" prop="sort">
          <el-input v-model.number="ruleForm.sort"></el-input>
        </el-form-item>
        <el-form-item label="是否启用:" prop="flag">
          <el-radio v-model="ruleForm.flag" :label="0">是</el-radio>
          <el-radio v-model="ruleForm.flag" :label="1">否</el-radio>
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>
</template>
<script>
export default {
  props: ["typeId"],
  watch: {
    typeId(val) {
      this.ruleForm.typeId = val;
    },
  },
  data() {
    return {
      ruleForm: { typeId: this.typeId },
      id: "",
      depatment: "",
      depatment1: "",
      depss: [],
      depss1: [],
      orole: "",
      oguid: "",
      rules: {
        name: [{ required: true, message: "名称不能为空", trigger: "blur" }],
        datavalue: [
          { required: true, message: "数据值不能为空", trigger: "blur" },
        ],
        details: [{ required: true, message: "描述不能为空", trigger: "blur" }],
        sort: [{ required: true, message: "排序值不能为空", trigger: "blur" }],
      },
    };
  },
  methods: {
    async submitForm(formName) {
      await this.$refs[formName].validate((valid) => {
        if (valid) {
          this.createDictionariesItem();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async createDictionariesItem() {
      let response = await this.api.platformApi.createDictionariesItem(
        this.ruleForm
      );
      if (response.data.data == true) {
        this.$message({
          message: "添加成功",
          type: "success",
        });
      } else {
        this.$message({
          message: "添加失败",
          type: "warning",
        });
      }
      this.resetForm('ruleForm');
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-select {
  width: 100%;
}
</style>