<template>
	<div id="channel">

		<el-form :inline="true" :model="queryParams" class="demo-form-inline">
			<el-row :gutter="24">
				<el-col :span="6">
					<div>
						<el-form-item label="接口名称:" prop="account">
							<el-input ref="queryParams" v-model="queryParams.channelName" placeholder="请输入接口名称">
							</el-input>
						</el-form-item>
					</div>
				</el-col>
<!-- 				<el-col :span="6" v-if="username == 'admin'">
					<div class="grid-content bg-purple">
						<el-form-item label="平台名称:">
							<el-input v-model="queryParams.platform" placeholder="请输入平台名称"></el-input>
						</el-form-item>
					</div>
				</el-col> -->
				<el-col :span="5">
					<el-form-item id="anniu">
						<el-button type="primary" icon="el-icon-search" @click="onSubmit">查询</el-button>
						<el-button type="primary" @click="rest" icon="el-icon-refresh-right">重置</el-button>
					</el-form-item>
				</el-col>
			</el-row>


		</el-form>


		<el-table id="table" :data="dataList" :header-cell-style="tableHeader" :cell-style="tableCell" border>
<!-- 			<el-table-column type="index" prop="id" label="编号" :show-overflow-tooltip="true" align="center" width="50px">
				<template slot-scope="scope">
					<span>{{(scope.$index+1) + ((queryParams.pageNo-1) * queryParams.pageSize)}}</span>
				</template>
			</el-table-column> -->
			<el-table-column prop="channelId" label="渠道编号" :show-overflow-tooltip="true" align="center" />
			<el-table-column prop="channelName" label="接口名称" :show-overflow-tooltip="true" align="center" />
			<el-table-column prop="url" label="接口路径" :show-overflow-tooltip="true" align="center" />
			<el-table-column prop="accessKeyId" label="接口key" :show-overflow-tooltip="true" align="center" />
			<el-table-column prop="accessKeySecrect" label="接口secrect" :show-overflow-tooltip="true" align="center" />
			<el-table-column prop="remark" label="备注" :show-overflow-tooltip="true" align="center" />
			<el-table-column prop="isvalid" label="状态" :show-overflow-tooltip="true" align="center">
				<template slot-scope="scope">
					<span v-if="scope.row.isvalid === false">关闭</span>
					<span v-if="scope.row.isvalid === true">启用</span>
				</template>
			</el-table-column>
			<el-table-column prop="createTime" label="更新时间" :show-overflow-tooltip="true" align="center">
				<template slot-scope="scope">
					<span>{{ dateTimeStamp(scope.row.createTime) }}</span>
				</template>
			</el-table-column>
			<!-- <el-table-column prop="tenantName" label="租户" :show-overflow-tooltip="true" align="center"> -->
			</el-table-column>

			<el-table-column fixed="right" label="操作" width="100" align="center">
				<template slot-scope="scope">
					<!-- <el-link type="primary" @click="showLog(scope.row)" :underline="false" size="small" style="color:#1D90FF">修改</el-link> -->
					<el-link type="primary" v-if="scope.row.isvalid === false" @click="editChannelStatus(scope.row,'启用')" :underline="false"
					 size="small" style="color:#42B983">启用</el-link>
					<el-link type="primary" v-if="scope.row.isvalid === true" @click="editChannelStatus(scope.row,'停用')" :underline="false"
					 size="small" style="color:#FFAC1D">停用</el-link>
				</template>
			</el-table-column>

		</el-table>

		<div>
			<template>
				<el-pagination id="page" background @size-change="handleSizeChange" @current-change="pageChange" :page-sizes="[10, 15, 20]"
				 :page-size="queryParams.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</template>
		</div>
		<el-dialog class="tablebox" :close-on-click-modal="false" title="修改" :show-close="true" :visible.sync="dialogVisible">
			<edit-channel :sort="sigle" ref="order" @closeDialog="cancel"></edit-channel>
			<span slot="footer" class="dialog-footer">
				<el-button type="success" @click="cancel()">取消</el-button>
				<el-button type="primary" @click="confirm">确认</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
	import editChannel from './editChannel.vue'
	export default {
		data() {
			return {
				dialogVisible: false,
				total: 0,
				dataList: null,
				username: null,
				queryParams: {
					pageNo: 1,
					pageSize: 10,
					platform: "",
					channelName: "",
				},
				sigle: '',
			};
		},

		mounted: function() {
			this.username = this.$getCookie("name");
			this.loadList();
		},
		methods: {


			async editChannelStatus(val, des) {



				await this.$confirm('确定' + des + '该接口吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {

					var params = val;
					if (val.isvalid === true) {
						params.isvalid = false;
					} else {
						params.isvalid = true;
					}


					this.editChannelStatusApi(params)

				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					});
				});


			},


			async editChannelStatusApi(params) {
				let response = await this.api.platformApi.editChannel(params)
				if (response.data.code === 200) {
					this.$message.success('修改成功')
				}

			},

			dateTimeStamp(datetime) {
				if (datetime != null && datetime > 0) {
					var t = new Date(datetime);
					var year = t.getFullYear();
					var month = t.getMonth() + 1;
					var day = t.getDate();
					var hour = t.getHours();
					var min = t.getMinutes();
					var sec = t.getSeconds();
					var newTime = year + '-' +
						(month < 10 ? '0' + month : month) + '-' +
						(day < 10 ? '0' + day : day) + ' ' +
						(hour < 10 ? '0' + hour : hour) + ':' +
						(min < 10 ? '0' + min : min) + ':' +
						(sec < 10 ? '0' + sec : sec);
					return newTime;
				}
			},
			async loadList() {
				let response = await this.api.platformApi.channelList(this.queryParams)
				this.dataList = response.data.data.records
				this.total = response.data.data.total;
			},
			tableHeader(column) {
				return 'height:36px;background-color:#fafafa;color:#333333;padding:0';
			},
			tableCell(column) {
				return 'height:40px;background-color:#fafafa;color:#000000;padding:0';
			},
			onSubmit() {
				this.loadList();
			},
			rest() {
				this.queryParams.orgNo = null;
				this.queryParams.channelName = null;
			},
			pageChange(val) {
				this.queryParams.pageNo = val;
				this.loadList();
			},
			showLog(row) {
				this.dialogVisible = true;
				this.sigle = row
			},
			handleSizeChange(val) {
				this.queryParams.pageSize = val
				this.queryParams.pageNo = 1
				this.loadList();
				console.log(`每页 ${val} 条`);
			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
			},
			cancel() {
				this.loadList()
				this.dialogVisible = false;

			},
			confirm() {
				this.$refs.order.submitForm('ruleForm');
			}
		},
		components: {
			editChannel
		}
	};
</script>
<style scoped>
	#channel {
		background-color: white;
		padding: 15px 0 100px;
	}

	#channel #table {
		width: 95%;
		margin-top: 15px;
		margin-left: 10px;
		background-color: rgba(29, 144, 255, 0);
	}

	#channel #page {
		float: right;
		margin-right: 70px;
		margin-top: 15px
	}

	>>>.el-dialog__body {
		border-top: 1px solid rgba(228, 228, 228, 1);
		border-bottom: 1px solid rgba(228, 228, 228, 1);
		padding-left: 0;
		padding-right: 0;
	}

	#channel .tablebox {
		text-align: left;
	}

	#channel .el-dialog button {
		margin-right: 15px;
		padding: 0px;
		width: 80px;
		height: 32px;
		border-radius: 5px;
	}
</style>
