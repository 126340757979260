/**
 * 邮箱
 * @param {*} s
 */
function isEmail (s) {
    return /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/.test(s)
}
  
/**
 * 手机号码
 * @param {*} s
 */
function isMobile (s) {
    return /^1[0-9]{10}$/.test(s)
}
  
/**
 * 电话号码
 * @param {*} s
 */
function isPhone (s) {
    return /^([0-9]{3,4}-)?[0-9]{7,8}$/.test(s)
}
  
/**
 * URL地址
 * @param {*} s
 */
function isURL (s) {
    return /^http[s]?:\/\/.*/.test(s)
}
  
/**
 * 身份证
 * @param {*} s
 */
function isIdno (s) {
    return /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(s)
}
  
/* 小写字母*/
function isLowerCase(str) {
    return /^[a-z]+$/.test(str)
}

/* 大写字母*/
function isUpperCase(str) {
    return /^[A-Z]+$/.test(str)    
}

/* 大小写字母*/    
function isAlphabets(str) {
    return /^[A-Za-z]+$/.test(str)
}

/**
 * 校验字符串日期格式
 * 支持YYYYMMdd、YYYY-MM-dd、YYYY/MM/dd
 * @param {日期} str 
 */
function isDate_YYYYMMDD(str){
    //支持YYYYMMdd、YYYY-MM-dd、YYYY/MM/dd
    let regexs = [
        /^((([0-9]{3}[1-9]|[0-9]{2}[1-9][0-9]{1}|[0-9]{1}[1-9][0-9]{2}|[1-9][0-9]{3})(((0[13578]|1[02])(0[1-9]|[12][0-9]|3[01]))|((0[469]|11)(0[1-9]|[12][0-9]|30))|(02(0[1-9]|[1][0-9]|2[0-8]))))|((([0-9]{2})(0[48]|[2468][048]|[13579][26])|((0[48]|[2468][048]|[3579][26])00))0229))$/,
        /^((([0-9]{3}[1-9]|[0-9]{2}[1-9][0-9]{1}|[0-9]{1}[1-9][0-9]{2}|[1-9][0-9]{3})-(((0[13578]|1[02])-(0[1-9]|[12][0-9]|3[01]))|((0[469]|11)-(0[1-9]|[12][0-9]|30))|(02-(0[1-9]|[1][0-9]|2[0-8]))))|((([0-9]{2})(0[48]|[2468][048]|[13579][26])|((0[48]|[2468][048]|[3579][26])00))-02-29))$/,
        /^((([0-9]{3}[1-9]|[0-9]{2}[1-9][0-9]{1}|[0-9]{1}[1-9][0-9]{2}|[1-9][0-9]{3})\/(((0[13578]|1[02])-(0[1-9]|[12][0-9]|3[01]))|((0[469]|11)\/(0[1-9]|[12][0-9]|30))|(02-(0[1-9]|[1][0-9]|2[0-8]))))|((([0-9]{2})(0[48]|[2468][048]|[13579][26])|((0[48]|[2468][048]|[3579][26])00))-02-29))$/
    ]
    for(let index in regexs){
        //如果匹配条件，则true
        if(regexs[index].test(str)){
            return true;
        }
    }
    return false;
}

/**
 * 校验是否Date对象类型
 * @param {*} date 
 */
function isDate_Type(date){
    return date instanceof Date
}

/**
 * 校验是否是合理金额（不包含负数）
 * @param {金额} obj 
 */
function isAmount(obj){
    return /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/.test(obj)
}

/**
 * 校验是否数值
 * @param {*} value 
 */
function isNumber(value){
    return /^[-\+]?\d+(\.\d+)?$/.test(value)
}

/**
 * 校验是否整型
 * @param {*} value 
 */
function isInteger(value){
    return /^[-\+]?\d+$/.test(value)
}
export default{
    isEmail,
    isMobile,
    isPhone,
    isURL,
    isIdno,
    isLowerCase,
    isUpperCase,
    isAlphabets,
    isDate_YYYYMMDD,
    isDate_Type,
    isAmount,
    isNumber,
    isInteger,
}
