<template>
  <div id="productManagementDetails">
    <div id="button">
      <el-row>
        <el-col :span="1">
          <el-button
            type="primary"
            align="left"
            icon="el-icon-edit-outline"
            @click="findProduct"
            >配置产品</el-button
          >
        </el-col>
      </el-row>
    </div>

    <div class="textTips">
      <el-row>
        <el-col :span="4" align="left">
          <span>基本信息</span>
        </el-col>
      </el-row>
    </div>

    <div id="table">
      <table>
        <tr>
          <td>产品代码</td>
          <td>{{ product.code }}</td>
        </tr>
        <tr>
          <td>产品名称</td>
          <td>{{ product.name }}</td>
        </tr>
        <tr>
          <td>产品资金方</td>
          <td>
            <span v-for="(item, index) in fundSideTypeList" v-bind:key="index">
                <span v-for="(item2, index2) in product.fundSide" v-bind:key="index2">
                  <span v-if="item2 == item.datavalue && product.fundSide.length != index2+1">{{ item.name }}、</span>
                  <span v-else-if="item2 == item.datavalue">{{ item.name }} </span>
                </span>
            </span>
          </td>
        </tr>
        <tr>
          <td>产品详述</td>
          <td>{{ product.detail }}</td>
        </tr>
        <tr>
          <td>生效日期</td>
          <td>{{ product.effectiveDate }}</td>
        </tr>
        <tr>
          <td>失效日期</td>
          <td>{{ product.invalidDate }}</td>
        </tr>
      </table>
    </div>

    <div class="textTips">
      <el-row>
        <el-col :span="4" align="left">
          <span>业务相关</span>
        </el-col>
      </el-row>
    </div>

    <div id="table">
      <table>
        <tr>
          <td>贷款期限</td>
          <td v-if="product.loanTerm.isDays == true">按天</td>
          <td v-if="product.loanTerm.isDays == false">按月,
            <span v-for="(item, index) in product.loanTerm.monthTerm" v-bind:key="index">
              <span v-if="product.loanTerm.monthTerm.length != index+1">{{item}}、</span>
              <span v-else>{{item}}</span>
            </span>
            月</td>
        </tr>
        <tr>
          <td>还款间隔</td>
          <td v-if="product.loanTerm.isDays == true">按贷款天数还</td>
          <td v-if="product.loanTerm.isDays == false">按贷款月份还</td>
        </tr>
        <tr>
          <td>宽限期天数</td>
          <td>{{ product.graceTermDays }}</td>
        </tr>
        <tr>
          <td>利率模式</td>
          <td>
            <span v-for="(item, index) in interestRateTypeList" v-bind:key="index">
                  <span v-if="product.interestRateType == item.datavalue">{{ item.name }} </span>
            </span>
          </td>
        </tr>
        <tr>
          <td>罚息模式</td>
          <td>
            <span v-for="(item, index) in penaltyInterestTypeList" v-bind:key="index">
                  <span v-if="product.penaltyInterestType == item.datavalue">{{ item.name }} </span>
            </span>
          </td>
        </tr>
        <tr>
          <td>手续费模式</td>
          <td>
            <span v-for="(item, index) in serviceChargeTypeList" v-bind:key="index">
                  <span v-if="product.serviceChargeType == item.datavalue">{{ item.name }} </span>
            </span>
          </td>
        </tr>
        <tr>
          <td>允许提前还款</td>
          <td v-if="product.advanceRepayment == 0">否</td>
          <td v-if="product.advanceRepayment == 1">是</td>
        </tr>
        <tr>
          <td>单笔贷款最大金额</td>
          <td>{{ product.maxLoanAmount / 100 }}</td>
        </tr>
        <tr>
          <td>单笔最小金额</td>
          <td>{{ product.minLoanAmount / 100 }}</td>
        </tr>
        <tr>
          <td>最大借款次数</td>
          <td>{{ product.maxLoanNum }}</td>
        </tr>
      </table>
    </div>

    <div class="textTips">
      <el-row>
        <el-col :span="4" align="left">
          <span>评分卡设置</span>
        </el-col>
      </el-row>
    </div>

    <div id="list">
      <el-table
        :data="gradeSettingList"
        id="table"
        :header-cell-style="tableHeader"
        :cell-style="tableCell"
        border
      >
        <el-table-column
          prop="grade"
          label="评级"
          :show-overflow-tooltip="true"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="maxCreditAmount"
          label="最大授信金额"
          :show-overflow-tooltip="true"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.maxCreditAmount / 100 }}
          </template>
        </el-table-column>
        <el-table-column
          prop="creditInterestRate"
          label="授信利率"
          :show-overflow-tooltip="true"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.creditInterestRate / 10000 }}%
          </template>
        </el-table-column>
        <el-table-column
          prop="serviceChargeRate"
          label="手续费比例"
          :show-overflow-tooltip="true"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.serviceChargeRate / 10000 }}%
          </template>
        </el-table-column>
        <el-table-column
          prop="penaltyInterestRate"
          label="罚息利率"
          :show-overflow-tooltip="true"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.penaltyInterestRate / 10000 }}%
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      fundSideType: {
        code: "fundSideType",
      },
      fundSideTypeList:[],
      interestRateType:{
        code:"interestRateType"
      },
      interestRateTypeList:[],
      penaltyInterestType:{
        code:"penaltyInterestType"
      },
      penaltyInterestTypeList:[],
      serviceChargeType:{
        code:"serviceChargeType"
      },
      serviceChargeTypeList:[],
      product: {
        id:'',
        code: "",
        name: "",
        fundSide: [],
        detail: "",
        effectiveDate: "",
        invalidDate: "",
        loanTerm: {
          isDays: false,
          dayTerm: 0,
          monthTerm: [],
        },
        repaymentInterval: 0,
        graceTermDays: 0,
        interestRateType: -1,
        interestRate: 0,
        penaltyInterestType: -1,
        penaltyInterest: 0,
        serviceChargeType: -1,
        serviceChargeRate: 0,
        advanceRepayment: -1,
        initQuota: 0,
        creditValidDay: 0,
        maxLoanAmount: 0,
        minLoanAmount: 0,
        maxLoanNum: 0,
      },
      gradeSettingList: [],
      isThisPlatformTenantReturnValue:{
        code:0,
        msg:"非本平台租户,无法操作",
        data:false,
      },
    };
  },
  mounted: function () {
    let param = JSON.parse(localStorage.getItem("id"));
    console.log("接收参数", param);
    this.productInfo(param.id);
    this.gradeSettings(param.creditSettingId);
    this.fundSideTypes(this.fundSideType);
    this.interestRateTypes(this.interestRateType);
    this.penaltyInterestTypes(this.penaltyInterestType);
    this.serviceChargeTypes(this.serviceChargeType);
  },
  methods: {
    async determineIfThisIsAPlatformTenant(){
      var params = {
        tenantId:this.product.tenant,
      };
      let response = await this.api.preLoanManagementApi.determineIfThisIsAPlatformTenant(
        params
      );
       console.log("=========determineIfThisIsAPlatformTenant", response);
      if (null != response.data.data) {
        if(response.data.code!=200){
          this.isThisPlatformTenantReturnValue.msg = response.data.msg;
        }
        this.isThisPlatformTenantReturnValue.code = response.data.code;
        this.isThisPlatformTenantReturnValue.data = response.data.data;
      }
    },
    //判断是否为本平台租户
    async isThisPlatformTenant() {
      var params = {
        sessionId: this.getCookie("sessionId"),
        tenant:this.product.tenant,
      };
      let response = await this.api.preLoanManagementApi.isThisPlatformTenant(
        params
      );
      console.log("=========isThisPlatformTenantResponse", response);
      if (null != response.data.data) {
        if(response.data.code!=200){
          this.isThisPlatformTenantReturnValue.msg = response.data.msg;
        }
        this.isThisPlatformTenantReturnValue.code = response.data.code;
        this.isThisPlatformTenantReturnValue.data = response.data.data;
      }
    },
    async fundSideTypes(param) {
      let response = await this.api.platformApi.findDictionariesItemList(param);
      console.log("产品资金方数据字典返回结果", response);
      if (response.data.data != null) {
        this.fundSideTypeList = response.data.data;
      }
      console.log("产品资金方数据字典集合", this.fundSideTypeList);
    },
    async interestRateTypes(param) {
      let response = await this.api.platformApi.findDictionariesItemList(param);
      console.log("利率模式数据字典返回结果", response);
      if (response.data.data != null) {
        this.interestRateTypeList = response.data.data;
      }
      console.log("利率模式数据字典集合", this.interestRateTypeList);
    },
    async penaltyInterestTypes(param) {
      let response = await this.api.platformApi.findDictionariesItemList(param);
      console.log("罚息模式数据字典返回结果", response);
      if (response.data.data != null) {
        this.penaltyInterestTypeList = response.data.data;
      }
      console.log("罚息模式数据字典集合", this.penaltyInterestTypeList);
    },
    async serviceChargeTypes(param) {
      let response = await this.api.platformApi.findDictionariesItemList(param);
      console.log("手续费模式数据字典返回结果", response);
      if (response.data.data != null) {
        this.serviceChargeTypeList = response.data.data;
      }
      console.log("手续费模式数据字典集合", this.serviceChargeTypeList);
    },
    getCookie: function (cname) {
      let name = cname + "=";
      let ca = document.cookie.split(";");
      // console.log("获取cookie,现在循环")
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        // console.log(c)
        while (c.charAt(0) === " ") c = c.substring(1);
        if (c.indexOf(name) !== -1) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    tableHeader(column) {
      return "height:36px;background-color:#fafafa;color:#333333;padding:0";
    },
    tableCell(column) {
      return "height:40px;background-color:#fafafa;color:#000000;padding:0";
    },
    async productInfo(id) {
      var params = {
        id: id,
      };
      let response = await this.api.preLoanManagementApi.findByIdProduct(
        params
      );
      console.log("=========response", response.data.data);
      if (null != response.data.data) {
        this.product = response.data.data;
      }
      console.log("=========产品信息", this.product);
    },
    async gradeSettings(creditSettingId) {
      var params = {
        id: creditSettingId,
      };
      let response = await this.api.preLoanManagementApi.gradeSettingList(
        params
      );
      console.log("=========response", response.data.data);
      if (null != response.data.data) {
        this.gradeSettingList = response.data.data;
      }
      console.log("=========产品信息", this.gradeSettingList);
    },
    async findProduct() {
      await this.determineIfThisIsAPlatformTenant()
      console.log("determineIfThisIsAPlatformTenant",this.isThisPlatformTenantReturnValue)
      if(!this.isThisPlatformTenantReturnValue.data){
        if(this.isThisPlatformTenantReturnValue.code != 200){
          this.$message.error(this.isThisPlatformTenantReturnValue.msg);
          return
        }
        this.$message.success(this.isThisPlatformTenantReturnValue.msg);
        return
      }
      localStorage.setItem(
        "id",
        JSON.stringify({
          id:this.product.id,
          productId: this.product.id,
          state:2,
        })
      );
      this.$router.push({ name: "addProduct" });
    },
  },
};
</script>
<style scoped>
#productManagementDetails {
  background-color: white;
  padding-top: 15px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 100px;
}
#table {
  margin-top: 10px;
  width: 100%;
  background-color: rgba(29, 144, 255, 0);
}
#productManagementDetails table,
td {
  border: 1px solid #e8e8e8;
  border-collapse: collapse;
  line-height: 35px;
  margin-top: 3px;
}
table {
  width: 90%;
}
td:nth-child(odd) {
  background: #fafafa;
  width: 13%;
  text-align: center;
  font-size: 13px;
}
td:nth-child(even) {
  text-align: left;
  color: #7c7c7c;
  font-size: 13px;
}
.textTips {
  margin: 40px 0 10px 0;
}
</style>