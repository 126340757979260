<template>
  <div id="editPwd">
    <el-row type="flex" justify="center">
      <el-col :xs="24" :sm="16" :md="12">
        <el-form
          :model="ruleForm"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
          :rules="rules"
          status-icon
        >
          <input type="hidden" v-model="ruleForm.id" />
          <el-form-item label="用户名" prop="name">
            <el-input v-model="ruleForm.name" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="登录密码" prop="pass">
            <el-input
              v-model="ruleForm.pass"
            ></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="checkPass">
            <el-input
              v-model="ruleForm.checkPass"
            ></el-input>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  props: ["username"],
  watch: {
    username(val) {
      this.ruleForm = val;
    },
  },
  mounted: function () {
    this.loadCgy();
  },

  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        id: "",
        name: "",

        pass: "",
        checkPass: "",
      },
      rules: {
        pass: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validatePass2, trigger: "blur" }],
      },
    };
  },
  methods: {
    async editPassWord() {
      var params = {
        sessionId: this.$getCookie("sessionId"),
        user: this.ruleForm.id,
        newPwd: this.ruleForm.checkPass,
      };
      let response = await this.api.platformApi.resetPwdNotVerify(params);
      let data = response.data;
      if(data.code == 200){
        this.$message({
          message: "修改成功",
          type: "success",
        });
      }else{
        this.$message.error(data.msg);
      }
    },
    loadCgy: function () {
      this.ruleForm.id = this.username.id;
      this.ruleForm.name = this.username.name;
    },

    submitForm(formName) {
       this.$refs[formName].validate((valid) => {
         if(valid){
             this.editPassWord();
         }else{
            this.$message({
        message: "密码不一致修改失败",
        type: "error",
        duration:5000
      });
         }
       
      })
      
      
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style scoped>
#editPwd .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
#editPwd .avatar-uploader .el-upload:hover {
  border-color: #1d90ff;
}
#editPwd .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
#editPwd .avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>