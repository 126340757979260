<template>
  <el-dialog
    title="签署截止时间"
    :visible.sync="dialogFormVisible"
    width="500px"
  >
    <el-date-picker
      v-model="form.expiresTime"
      type="date"
      placeholder="选择日期"
      style="width: 100%"
      :picker-options="pickerOptions"
    >
    </el-date-picker>

    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import dayjs from 'dayjs'
export default {
  data() {
    return {
      dialogFormVisible: false,
      form: {
        extensionTime: '',
        id: '',
      },
      formLabelWidth: '120px',
      record: null,
      pickerOptions: {
        disabledDate(time) {
          // 禁用今天的日期之前的所有日期
          return time.getTime() < Date.now() - 3600 * 1000 * 24
        },
      },
    }
  },
  methods: {
    open(row) {
      this.record = row
      this.form.id = row.id
      this.dialogFormVisible = true
    },
    async submit() {
      if (!this.form.expiresTime) {
        return this.$message.error('请选择截止时间')
      }

      // 打印原始输入日期
      console.log('原始输入日期:', this.form.expiresTime);

      // 转换截止时间为 Unix 时间戳（以秒为单位）
      const expiresTime = dayjs(this.form.expiresTime, 'YYYY-MM-DD').endOf('day').unix();
      console.log('转换后的 Unix 时间戳:', expiresTime);

      // 验证延期时间
      if (
        this.record.endTime &&
        dayjs.unix(this.record.endTime).isAfter(dayjs.unix(expiresTime))
      ) {
        return this.$message.error('延期时间不能在原有时间之前');
      }

      // 计算延期时间（以秒为单位）
      let extensionTime;
      if (this.record.endTime) {
        extensionTime = (expiresTime - this.record.endTime) * 1000; // 转换为毫秒
      } else {
        extensionTime = 0; // 或者其他默认值。
      }

      // 调用API更新记录
      this.api.contractApi.extension({
        id: this.record.id,
        extensionTime,
      }).then(res => {
        if (res.data.code === 200) {
          this.$message.success('延期成功');
          this.dialogFormVisible = false;
          this.$emit('success');
        } else {
          this.$message.error(res.data.msg);
        }
      });
    }
  },
}
</script>

