<template>
	<el-row type="flex" justify="center">
		<el-col :xs="24" :sm="16" :md="12">
			<el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<input type="hidden" v-model="ruleForm.id" />
				<el-form-item label="菜单名称" prop="name">
					<el-input v-model="ruleForm.name" :disabled="true"></el-input>
				</el-form-item>
				<el-form-item label="模块" prop="module">
					<el-input v-model="ruleForm.module" :disabled="true"></el-input>
				</el-form-item>
				<el-form-item label="路径" prop="path">
					<el-input v-model="ruleForm.path" :disabled="true"></el-input>
				</el-form-item>

				<el-form-item label="排序" prop="sequence">
					<el-input v-model="ruleForm.sequence " oninput="value=value.replace(/[^\d.]/g,'')"></el-input>
				</el-form-item>

			</el-form>
		</el-col>
	</el-row>
</template>

<script>
	export default {
		props: ["sort"],
		mounted: function() {
			this.loadCgy();
		},

		data() {
			return {
				ruleForm: {
					id: "",
					sequence: 0,
					name: "",
					module: "",
					path: "",
				},

			};
		},
		watch: {
			sort(val) {
				this.ruleForm = val
			}
		},
		methods: {
			loadCgy: function() {

				this.ruleForm = this.sort
			},
			async submitForm(formName) {
				await this.$refs[formName].validate((valid) => {
					if (valid) {
						var params = {
							id: this.ruleForm.id,
							sequence: this.ruleForm.sequence,
							sessionId: this.$getCookie("sessionId"),
						}
						this.editmenu(params);
					} else {
						return false;
					}
				});
			},

			async editmenu(params){
			 let response = await this.api.platformApi.editmenu(params)
				if(response.data.data=true){
					this.$message({
					message: "修改成功",
					type: "success",
				});
				
				}else{
					this.$message({
					message: "修改失败",
					type: "warning",
        		});
				}
				this.$emit('closeDialog');
				window.location.reload()
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
		},
	};
</script>

<style scoped>
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409eff;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>
