<template>
  <div id="organizationPage">
    <el-row :gutter="24">
      <el-col :span="2" :pull="0">
        <div class="grid-anniu">
          <template>
            <el-button @click="addUser()" icon="el-icon-plus" type="primary"
              >添加机构</el-button
            >
          </template>
        </div>
      </el-col>
    </el-row>
    <el-dialog
      class="dialogBox"
      :close-on-click-modal="false"
      title="添加机构"
      :show-close="true"
      :visible.sync="dialogVisibleadd"
      v-if="dialogVisibleadd"
    >
      <add-organization :add="123" ref="addOrganization"></add-organization>
      <span id="anniu" slot="footer" class="dialog-footer">
        <el-button @click="cancel()">取消</el-button>
        <el-button type="primary" @click="insertOrganization">添加</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      title="修改机构"
      :show-close="true"
      @close="aclose()"
      :visible.sync="dialogVisibleedit"
      width="60%"
      height="100%"
      v-if="dialogVisibleedit"
    >
      <edit-organization :editOrganization="editsigle" ref="editOrganization">
      </edit-organization>
      <span slot="footer" class="dialog-footer">
        <el-button @click="canceledit()">离开</el-button>
        <el-button type="primary" class="succussfulButtom" @click="editx"
          >确认</el-button
        >
      </span>
    </el-dialog>
    <el-table
      id="table"
      :data="list"
      :header-cell-style="tableHeader"
      :cell-style="tableCell"
      border
    >
      <el-table-column prop="sss" width="100" label="编号">
        <template slot-scope="scope">
          <span>{{
            scope.$index + 1 + (queryParams.pageNo - 1) * queryParams.pageSize
          }}</span>
        </template>
      </el-table-column>

      <el-table-column
        prop="code"
        label="机构编号"
        :show-overflow-tooltip="true"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="name"
        label="机构名称"
        :show-overflow-tooltip="true"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="abbreviation"
        label="机构简称"
        :show-overflow-tooltip="true"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="address"
        label="机构地址"
        :show-overflow-tooltip="true"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="company"
        label="所属公司"
        :show-overflow-tooltip="true"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="content"
        label="机构描述"
        :show-overflow-tooltip="true"
        align="center"
      />
      <el-table-column
        v-if="show"
        prop="state"
        label="state"
        :show-overflow-tooltip="true"
        align="center"
      />
      <el-table-column
        v-if="show"
        prop="id"
        label="id"
        :show-overflow-tooltip="true"
        align="center"
      />

      <el-table-column prop="id" v-if="show" label="rid" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-row :gutter="0">
            <el-col :span="8">
              <div class="grid-content bg-purple">
                <el-link
                  type="primary"
                  icon="el-icon-editSysUserList"
                  @click="updateOrganization(scope.row)"
                  :underline="false"
                  size="medium"
                  >修改</el-link
                >
              </div>
            </el-col>

            <el-col :span="8">
              <div class="grid-content bg-purple">
                <div v-if="scope.row.state == 1">
                  <el-link
                    type="warning"
                    bflag
                    @click="editState(scope.row)"
                    :underline="false"
                    size="medium"
                    >停用</el-link
                  >
                </div>
                <div v-if="scope.row.state == 0">
                  <el-link
                    type="success"
                    bflag
                    @click="editState(scope.row)"
                    :underline="false"
                    size="medium"
                    >启用</el-link
                  >
                </div>
              </div>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      id="page"
      background
      @size-change="handleSizeChange"
      @current-change="pageChange"
      :page-sizes="[10, 15, 20]"
      :page-size="queryParams.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>
<script>
import addOrganization from "./addOrganization";
import editOrganization from "./editOrganization";
import { organization } from "@/service/moudules/platformApi";
import { organizationorganizationDisable } from "@/service/moudules/platformApi";
import { organizationOpen } from "@/service/moudules/platformApi";
export default {
  data() {
    return {
      msg: "",
      show: false,
      created() {},
      dialogVisibleedit: false,
      dialogVisibleadd: false,
      editsigle: "",
      formInline: {
        id: "",
        name: "",
        address: "",
        flag: "",
        content: "",
        abbreviation: "",
        code: "",
        company: "",
      },
      flagType: {
        id: "",
      },
      total: 0,
      queryParams: {
        pageNo: 1,
        pageSize: 10,
      },

      list: [],
    };
  },
  mounted: function () {
    this.loadList();
    this.handleSizeChange(10);
  },
  methods: {
    editx() {
      this.$refs.editOrganization.submitForm("ruleForm");
    },
    updateOrganization(row) {
      this.dialogVisibleedit = true;
      this.editsigle = row;
    },

    insertOrganization() {
      this.$refs.addOrganization.submitForm("ruleForm");
    },
    cancel() {
      this.dialogVisibleadd = false;
      this.loadList();
      this.handleSizeChange(10);
    },
    canceledit() {
      this.dialogVisibleedit = false;
      this.loadList();
      this.handleSizeChange(10);
    },
    addUser() {
      this.dialogVisibleadd = true;
    },
    //修改启动按钮
    editState(row) {
      this.flagType.id = row.id;
      if (row.state == 0) {
        this.organizationOpenApi(row.id);
      } else {
        this.organizationDisableApi(row.id);
      }
    },
    tableHeader(column) {
      return "height:36px;background-color:#fafafa;color:#333333;padding:0";
    },
    tableCell(column) {
      return "height:40px;background-color:#fafafa;color:#000000;padding:0";
    },
    handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.loadList();
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },

    // loadList: function () {
    //   var temp = this;

    //   axios
    //     .post(
    //       "/sys-organization/findOrganizationPage",
    //       JSON.stringify(temp.queryParams),
    //       this.$jsonHeaders
    //     )
    //     .then(function (response) {
    //       temp.list = response.data.data.records;

    //       temp.total = response.data.data.total;
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     })
    //     .then(function () {});
    // },
    async loadList() {
      let response = await this.api.platformApi.organization(this.queryParams);
      // var s = JSON.stringify(response.data);
      this.list = response.data.data.records;

      this.total = response.data.data.total;
    },
    async organizationOpenApi() {
      let response = await this.api.platformApi.organizationOpen(this.flagType);
      this.loadList();
    },
    async organizationDisableApi() {
      let response = await this.api.platformApi.organizationDisable(
        this.flagType
      );
      this.loadList();
    },
    pageChange(val) {
      this.queryParams.pageNo = val;

      this.loadList();
    },

    setCookie: function (cname, cvalue, exdays) {
      let d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      let expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + "; " + expires;
    },
    getCookie: function (cname) {
      let name = cname + "=";
      let ca = document.cookie.split(";");
      // console.log("获取cookie,现在循环")
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        // console.log(c)
        while (c.charAt(0) === " ") c = c.substring(1);
        if (c.indexOf(name) !== -1) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    clearCookie: function (cname) {
      this.setCookie(cname, "", -1);
    },
  },
  components: {
    addOrganization,
    editOrganization,
  },
};
</script>
<style scoped>
#organizationPage {
  background-color: white;
  height: 100%;
}
.grid-anniu button {
  padding: 0px;
  width: 120px;
  height: 32px;
  border-radius: 5px;
  margin: 10px;
}
#anniu {
  border: 0;
}

#anniu button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}
>>> #anniu > .el-form-item__content {
  width: 200px;
}


.dialogBox {
  text-align: left;
}

#page {
  float: right;
  margin-right: 70px;
  margin-top: 15px;
}
#table {
  width: 95%;
  margin-top: 15px;
  margin-left: 10px;
  background-color: rgba(29, 144, 255, 0);
}
#header {
  background-color: white;
  padding: 15px 0 100px;
}
.el-input > input {
  height: 32px;
  border-radius: 5px;
  border: 2px solid #e8e8e8;
  width: 200px;
}
>>> .el-dialog__body {
  border-top: 1px solid rgba(228, 228, 228, 1);
  border-bottom: 1px solid rgb(228, 228, 228, 1);
  padding-left: 0;
  padding-right: 0;
}
.el-select {
  width: 210px;
}
>>> .el-drawer.rtl {
  overflow: scroll;
}
</style>