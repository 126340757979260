import {accMul} from './calculateUtils'

let q = 5000000;//5万
let w = 10000000;//10万
let e = 50000000;//50万
let r = 100000000;//100万

/**
 ** 提现手续费
 ** 返回值：手续费用
 **/
function withdrawalServiceCharge(c){

    //小/等于5万 4元/笔
    if (c > 400 && c <= q) {
        return 400;
    }
    //5-10万(含) 11元/笔
    if (c > q && c <= w) {
        return 1100;
    }
    //10-50万(含) 17元/笔
    if (c > w && c <= e) {
        return 1700;
    }
    //50-100万(含) 24元/笔
    if (c > e && c <= r) {
        return 2400;
    }
    if (c > r) {
        console.log("提现金额大于",r);
        let l = accMul(c,0.000024)

        if (l > 24000) {
            return 24000;
        }
        return l;
    }

    return 0;
}

export{
    withdrawalServiceCharge
}