/**
 * 判断比较值是否为空
 * @param {比较值} obj 
 */
function isEmpty(obj){
    // console.log(obj,'==>',typeof obj ,(obj instanceof String))
    if(obj == undefined || obj == null){
        return true;
    }
    //如果是数组或者对象
    if(obj instanceof Object){
        return Object.keys(obj).length == 0;
    }else if (obj.constructor === String){
        return obj.length == 0;
    }
    return false;
}

export default {
    isEmpty
}